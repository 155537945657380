.btn-remove {
  position: absolute;
  right: 0;
  top: 0;

  @include media-breakpoint-up(sm) {
    position: relative;
  }
}

.cart-qty-selector {
  max-width: 12rem;
}

@include media-breakpoint-up(sm) {
  .vertical-align-checkout {
    display: flex;
    flex-direction: column;
    min-height: 128px;
    justify-content: center;
  }
}

.col-total-checkout {
  @extend .mt-2;
  @extend .mt-sm-0;
  @extend .font-weight-bold;
  @extend .vertical-align-checkout;

  @include media-breakpoint-up(sm) {
    text-align: right;
  }
}

.step-checkout-disabled {
  @extend .bg-transparent;
  @extend .text-dark;
}

.order-vertical {
  .col-lg-1, .col-3, .col-6, .col-md-3 , .col-md-4 {
    @extend .d-flex;
    @extend .align-items-center;
  }
}