#filter-container {
  @include media-breakpoint-up(md) {
    display: block;
  }
}

.filter {
  .name {
    @extend .font-weight-bold;
    @extend .mb-2;
    @extend .text-truncate;
    @extend .text-uppercase;
  }

  .content-filter {
    @extend .mb-3;
    @include media-breakpoint-up(sm) {
      max-height: 140px;
      overflow-y: auto;
    }

    a {
      color: $gray-900;
      line-height: 1em;
      padding-left: 1.5em;
      position: relative;
      @extend .mb-2;

      .name {
        @extend .font-weight-bold;
        text-transform: uppercase;
      }

      &:before, .option-image {
        border: 1px solid $secondary;
        display: inline-block;
        height: 1em;
        vertical-align: top;
        width: 1em;
      }

      &:before {
        content: '';
        left: 0;
        position: absolute;
        top: 0;
      }

      &.active {
        &:before {
          background-color: $primary;
        }
      }
    }
  }

}