$color-social:           $gray-400;

@include media-breakpoint-down(md) {
  .dropdown-m {
    width: 100%;
  }
}

.dropdown-cart {
  width: 300px;
}

#sl-map-container {
  height: 100%;
  min-height: 450px;
}

.user-marker-selector {
  position: absolute;
  z-index: 5;
  top: 10px;
  right: 10px;
  background-color: white;
}

#showUserMarkerSelector {
  padding: 10px;
}


.may-also-like {
  position: relative;
  .sticker {
    position: absolute;
    top: 0;
    left: 5px;
  }
}

.jumbotron {
  .featured-text {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
  }
}

.carousel-control-prev,
.carousel-control-next {
  background-color: $gray-700;
  height: $carousel-control-width;
  margin-top: -(($carousel-control-width) / 2);
  opacity: 0.9;
  top: 50%;
  width: $carousel-control-width;
}

#blog-sidebar {
  .input-group-sm {
    & > .form-control {
      padding: 1.125rem .75rem;
      color: $dark;
      border-color: $dark;
    }
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  padding: 15px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  font-weight: 900;
  cursor: pointer;
  background-color: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover, &:focus {
    outline: none;
    background-color: transparent;
    color: transparent;
    &:before {
      opacity: 1;
    }
  }
  &.slick-disabled:before {
    opacity: 0.5;
  }
  &:before {
    font-family: "Font Awesome 5 Free";
    font-size: 20px;
    line-height: 1;
    color: black;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -20px;
  [dir="rtl"] & {
    left: auto;
    right: -20px;
  }
  &:before {
    content: "\f053";
    [dir="rtl"] & {
      content: "\f054";
    }
  }
}

.slick-next {
  right: -20px;
  [dir="rtl"] & {
    left: -20px;
    right: auto;
  }
  &:before {
    content: "\f054";
    [dir="rtl"] & {
      content: "\f053";
    }
  }
}

//Select cart subscription
.custom-select {
  position: relative;
  min-width: 240px;
  .form-control:focus {
    box-shadow: none;
  }
}
.select-css {
  position: relative;
  bottom: 7px;
  z-index: 10;
  background-color: transparent !important;
  cursor: pointer;
  border: none;
  &::-ms-expand {
    display: none;
  }
}
.custom-select-icons {
  position: absolute;
  top: 0.2em;
  right: 0.5em;
  z-index: 10;
  border: 1px solid white;
  background: white;
  cursor: pointer;
}
.custom-select-options {
  border: 1px solid #aaa;
  border-radius: 0 0 0.25em 0.25em;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: normal;
  cursor: pointer;
  z-index: 3;
  position: absolute;
  width: 100%;
  min-width: 240px;
  background-color: #ffffff;
  color: $primary;
  left: 0px;
  top: 37px;
  li {
    padding: 1em;
    border: 1px solid white;
    &.selected {
      background-color: $dropdown-link-active-color;
      border-color: $dropdown-link-active-color;
      color: $primary;
    }
    &:hover {
      background-color: $dropdown-link-hover-bg;
      border-color: $dropdown-link-hover-bg;
      color: $primary;
    }
  }

}
.hidden-visually {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.btn-remove {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  background-color: transparent;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: normal;
  border-radius: 0;
  font-weight: bold;
  padding: 0 !important;
  right: 0;
  top: 0;
}

@include media-breakpoint-down(md) {
  .btn {
    font-size: 0.9rem;
  }
}