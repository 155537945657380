/******************************************************************************
 * Quickbuy modal
 ******************************************************************************/
[data-zg-role="bundle-product-rule"].disabled{
  opacity:.5;
}
.product-details {
  header {
    border-bottom: 1px solid $gray-400;

    .name {
      color: $primary;
    }
  }
  a.thumbnail {
    display: block;

    &.active {
      border-bottom: 5px solid;
    }
  }

  .product-filters {
    .btn {
      &.image-option {
        padding: 0;
      }
    }
  }

  #zoomMain {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }
  }
}

.sticker {
  left: 10px;
  position: absolute;
  top: 0;
  z-index: $zindex-dropdown;
}

/******************************************************************************
 * Category page and related products
 ******************************************************************************/
.product-preview {
  article {
    height: calc(100% - 2em);
    @extend .pt-3;

    &:hover {
      box-shadow: 0px 0px 5px $gray-400;
    }
  }

  .card-header {
    display: flex;
    justify-content: space-between;
  }
  .product-content {
    position: relative;
    .sticker {
      position: absolute;
      top: 0;
      z-index: $z-index-100;
    }

    .btn-compare-quickbuy {
      display: none;
      padding: 0.1em 0.4em;
      position: absolute;
      right: 1em;
      top: 0;
      z-index: 1;
    }

    .images-container {
      .container-btn-quickbuy {
        display: none !important;
        left: 10%;
        position: absolute;
        top: 50%;
        width: 80%;

        @include media-breakpoint-up(sm) {
          top: 80%;
        }
      }

      img {
        position: relative;
        top: auto;
        left: auto;
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;

        @include transition(opacity 0.4s ease-out);
      }

      img + img {
        position: absolute;
        top: 50%;
        opacity: 0;
        right: 50%;
        transform: translate(50%, -50%);
      }

      &:hover {
        img {
          opacity: 1;
        }
        .container-btn-quickbuy {
          display: flex !important;
        }
      }
    }

    &:hover {
      .btn-compare-quickbuy {
        display: block;
      }
    }
  }

  .product-options {
    ul {
      li {
        display: inline-block;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
    .btn {
      padding: ($line-height-base / 4);
      line-height: $line-height-base;
    }

    img {
      border: 1px solid $gray-600;
      display: block;
      height: 1.5rem;
    }
  }
  .btn-group-justified {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .btn-group {
      flex-grow: 1;
    }
  }
}

.featured-products {
  @extend .align-items-center;
  @extend .d-flex;
  @extend .h3;
  @extend .text-center;
  @extend .text-primary;
}

.bundle-availability{
  .availability_units{
    display: none;
    &.quantity-0{
      display: block;
      margin-top: -5px;
      margin-bottom: 5px;
      color: $red;
    }
  }
}

select.form-control option:disabled{
  color: $gray-300;
}

//Fix for iphone zoom problem
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .form-control-sm {
    font-size: 16px;
  }
}


.flex-price {
  display: flex;
}

.subscribe-price {
  display: flex;
  align-items: center;
  h3 {
    text-decoration: line-through;
    font-size: 18px;
    color: #666;
    margin-left: 10px;
  }
}

.hide-price {
  display: none!important;
}

.subscribe-and-save {
  margin-bottom: 10px;
}

.subscribe-radio {
  padding: 16px;
  border: 1px solid #EBEBEB;
  border-bottom: none;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  min-height: 57px;
  label {
    margin-bottom: 0;
    font-weight: normal;
  }
  input {
    margin-right: 12px;
    margin-top: 3px;
  }
  .save-badge {
    font-size: 0.7rem;
    margin-left: 40px;
    padding: 0 10px;
    border: 1px solid;
    border-color: $success;
  }
}

.discount-badge {
  border: 1px solid;
  border-color: $success;
  font-weight: bold;
  font-size: 0.5rem;
  display: flex;
  position: relative;
  bottom: 3px;
  .discount-badge-divider {
    margin: 0 4px;
  }
}

.product-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.margin-fix {
  margin-right: 15px;
  margin-left: 15px;
}